
import api from '@/api/co.api'
import web from '@/api/web/co.web'
import cookies from '@/api/lib/co.lib.cookie'

export default {
  data () {
    return {
      navHome: {
        text: '关注',
        disabled: false,
        href: '/',
      },
      navSelf: {
        text: '',
        disabled: false,
        href: '',
      },
      navCurrent: {
        text: '',
        disabled: true,
        href: '',
      },
      banApp: {
        assistColor: 'primary',
        divisible: false,
        crumbs: []
      },
      classify: {
        height: 720,
        points: {
          xs: 200,
          sm: 320,
          md: 410,
          lg: 720,
          xl: 800
        },
        cycle: true,
        interval: 8000,
        hideDelimiters: false,
        showArrows: true
      },
      banType: '',
      banParams: null,
      banItems: [],
      banTimer: null
    }
  },
  computed: {
    wdHideDelimiters () {
      if (this.banItems.length > 1) {
        return this.classify.hideDelimiters
      } else {
        return true
      }
    },
    wdShowArrows () {
      if (this.banItems.length > 1) {
        return this.classify.showArrows
      } else {
        return false
      }
    }
  },
  methods: {
    configClassify (topic, defValue = {}) {
      const pattern = topic.pattern || {}
      const cls = pattern.classify || defValue || {}
      Object.assign(this.classify, cls)
    },
    setCrumbSelf (self = {}) {
      if (!self) {
        return
      }

      if (!self.title) {
        return
      }

      this.navSelf.text = self.title
      this.navSelf.href = self.href
      this.navSelf.disabled = self.disabled === true
    },
    setCrumbs (navigation = null, disabled = true) {
      // console.log('setCrumbs navigation: %o', navigation)
      this.banApp.crumbs = []
      if (navigation && navigation.title) {
        this.navCurrent.text = navigation.title
        this.navCurrent.disabled = disabled
        this.navCurrent.href = navigation.href || ''

        this.navSelf.disabled = false

        this.banApp.crumbs.push(
          this.navHome,
          this.navSelf,
          this.navCurrent
        )
      } else {
        this.navSelf.disabled = true
        this.banApp.crumbs.push(
          this.navHome,
          this.navSelf
        )
      }

      this.banApp.divisible = true
    },
    initBannerParams ({
      type = web.comm.BannerTypes.HOME_MAJOR
    }) {
      // const me = this
      const executed = function (res) {
        // console.log('me.banItems: %o', me.banItems)
        if (res.status) {
        }
      }

      this.banParams = web.banner.getParams({
        type,
        state: web.comm.States.PUBLISH,
        caches: this.banItems,
        executed
      })
    },
    loadBanners (reload = true) {
      this.banParams.reload = reload
      api.httpx.getItems(this.banParams)
    },
    toBanner (item) {
      const params = {
        bannerId: item.bannerId,
        type: item.type,
        title: item.title
      }

      const name = api.link.noticeDetail
      cookies.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
  }
}
