
import api from '@/api/co.api'
import data from '@/data/co.data'

import mixBannerBase from '@/pages/mixins/mix.banner.base'

export default {
  mixins: [mixBannerBase],
  data () {
    return {
      btnJoin: {
        text: '加入我们'
      }
    }
  },
  created () {
    this.banItems = data.banner.home
  },
  methods: {
    onJoin (ev) {
      const params = {
        mode: 'phone'
      }
      api.page.navigate(this, 'login', params)
    },
    onClick (ev) {
      const item = ev.item || {}
      const link = item.link || ''
      if (link) {
        window.open(link, '_blank')
      }
    },
  }
}
