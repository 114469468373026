<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
      @action="onJoin"
      @click="onClick"
    />

    <card-scenes :items="sceItems" />

    <guide-products />

    <card-schemes :items="schItems" />

    <guide-environment />
    <section-foot />
  </section>
</template>

<script>
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import data from '@/data/co.data'

  import mixHomeBanner from '@/pages/mixins/home/mix.home.banner'

  export default {
    metaInfo () {
      return {
        title: this.title
      }
    },
    components: {
      CardScenes: () => import('@/pages/sections/comm/CardScenes.vue'),
      CardSchemes: () => import('@/pages/sections/comm/CardSchemes.vue'),
      GuideProducts: () => import('@/pages/sections/home/GuideProducts.vue'),
      GuideEnvironment: () => import('@/pages/sections/home/GuideEnvironment.vue'),
      SectionFoot: () => import('@/pages/sections/Foot.vue'),
    },
    mixins: [
      mixHomeBanner
    ],
    data () {
      return {
        logined: false,
        title: '首页',
        sceItems: [],
        schItems: data.scheme.items,
      }
    },
    created () {
      app.on(app.event.USER_COVER_CHANGE, this.changeCover)
      app.on(app.event.USER_COVER_CHOOSE, this.chooseCover)
      app.on(app.event.HOME_CHECK_LOGIN, this.checkLogined)

      this.sceItems = data.scene.getItems('', 6)
    },
    destroyed () {
      app.off(app.event.USER_COVER_CHANGE)
      app.off(app.event.USER_COVER_CHOOSE)
      app.off(app.event.HOME_CHECK_LOGIN)
    },
    mounted () {
    },
    methods: {
      changeCover (ev) {
        const frm = this.$refs['issueCenter']
        if (frm) {
          frm.resetCover(ev)
        }
      },
      chooseCover (ev) {
        const frm = this.$refs['dlgCover']
        if (frm) {
          frm.show()
        }
      },
      checkLogined () {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.title = '关注'
            me.logined = true
          } else {
            me.title = '首页'
            me.logined = false
          }
        }

        user.login.isLogined(callback)
      }
    }
  }
</script>
